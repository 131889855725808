@import "../../../bootstrap/functions";
@import "../../variables";
@import "../../../bootstrap/mixins";

@import "../../smb/components/_smb-carousel.scss";

@import "../../smb/helpers/_smb-infinity-carousel.scss";

// SECTIONS
.section {
	padding-top: $base-unit * 8;
    padding-bottom: $base-unit * 8;

    &--carousel {
        background-image: url("../../../../../i/v2/components/bg/bg-blade-dark.svg");
        background-color: $body-bg-inverse;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;        
    }

    &--features {
        padding-bottom: $base-unit * 5;
    }
}

// HERO
.hero {
    .hero-image-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hero-image-cta {
        position: absolute;
        z-index: 1;
    }
    .hero-image {
        position: relative;

        &::after {
            position: absolute;
            content: '';
            display: block;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba($blue-dark, 0.4);
            mix-blend-mode: darken;
        }
    }
}
