@import "../../../bootstrap/functions";
@import "../../../bootstrap/mixins";
@import "../../variables";

// Tweak before and after pseudo classes
// SMB Infinity carousel (slider) with inverse parent class

.inverse {
    .carousel-slider {
        &.single {
            .tns-inner {
                &:before, &:after {
                    background-image: none;
                }
            }
        }
    }
}
