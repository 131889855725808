@import "../../../bootstrap/functions";
@import "../../variables";
@import "../../../bootstrap/mixins";

// SMB CAROUSEL
.smb-carousel {
	&__item {
		text-align: center;
		flex-direction: column;
		justify-content: flex-end;

		h3, p {
			opacity: 0;
		}

		&.tns-slide-active {
			h3, p {
				opacity: 1;
			}
		}
	}

	&__img {
		display: block;
		max-width: 100%;

		&-wrapper {
			position: relative;
			display: inline-block;
		}

		&-content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

// WITH MONITOR CLASS WRAP
.monitor {
	padding: ($base-unit * 8) 0;
    background-image: url("../../../../../i/v2/components/bg/bg-blade-dark.svg");
    background-color: $body-bg-inverse;
    background-repeat: no-repeat;
    background-position: center center;
	background-size: cover;
	h2 {
        text-align: center;
	}
	.smb-carousel {
		position: relative;
		&__item {
			position: relative;
			&.tns-slide-active {}
			img {
				max-width: 90%;
			}
		}
		&-controls {
			span {
				width: ($base-unit * 3);
				height: ($base-unit * 3);
				display: block;
				border-radius: 50%;
				background-color: $orange-bright;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				box-shadow: 0 12px 15px -4px rgba(255, 120, 0, 0.3), 0 12px 15px -4px rgba(12, 5, 62, 0.1);
				cursor: pointer;
				&.prev {
					left: 0;
					background: $orange-bright url("../../../../../i/v2/components/prev-s-white.svg") center center no-repeat;
					background-size: auto 20px;
				}
				&.next {
					right: 0;
					background: $orange-bright url("../../../../../i/v2/components/next-s-white.svg") center center no-repeat;
					background-size: auto 20px;
				}
				&:focus {
					outline: none;
				}
				&:hover {
					background-color: $orange-light;
				}
			}
		}
		.tns-nav {
			display: flex;
			align-items: center;
			justify-content: center;
			transform: translateY(-100%);
			position: relative;
			z-index: 9;
			button {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: $gray05;
				margin: 0 $base-unit * 0.75;
				padding: 0;
				border: 0;
				&:focus {
					outline: none;
				}
				&.tns-nav-active {
					width: 8px;
					height: 8px;
					background-color: $orange-bright;
				}
			}
		}
	}
}
